.video-parent .vjs-button>.vjs-icon-placeholder:before {
  line-height: 1.2;
}

.video-parent .video-js .vjs-progress-holder .vjs-play-progress,
.video-parent .video-js .vjs-progress-holder .vjs-load-progress,
.video-parent .video-js .vjs-progress-holder .vjs-load-progress div {
  color: red;
}

.video-parent .video-js .vjs-play-progress {
  background-color: red;
}

.video-parent .video-js .vjs-progress-control {
  width: 85vw;
  position: absolute;
  top: -1rem;
  height: 1rem;
}

@media only screen and (max-width: 1500px) {
  .video-js .vjs-progress-control {
     width: 80vw;
     position: absolute;
     top: -.8rem;
   }
 }
 .video-js .vjs-duration {
   display: block;
   position: absolute;
   right:5vw;
   font-size: 14px;
   top: -1.8rem;
   width: 3vw;
 }
 .vjs-remaining-time{
   display: none;
 }
 .video-js .vjs-current-time  {
   display: block;
   position: absolute;
   left:2vw;
   font-size: 14px;
   top: -1.8rem;
   width: 3vw;
 }

.video-parent .video-js .vjs-remaining-time span:nth-child(2) {
  display: none;
}

/* .video-js .vjs-duration {
  display: block;
}
.video-js .vjs-remaining-time,.vjs-time-divider,.vjs-current-time {
  display: none ;
} */


.video-parent .video-js .vjs-control-bar {
  height: 4rem;
  background-color: transparent;
  width: 100%;
  justify-content: center;
}

.video-parent .vjs-title-bar {
  background: transparent;
  margin-left: -1.5rem;
}

.video-parent .vjs-control-bar span,
.video-parent .vjs-playback-rate .vjs-playback-rate-value {
  font-size: 20px;
}

/* 
.video-parent .vjs-subs-caps-button {
  position: absolute;
  right: 0;
} */

.video-parent .video-js .vjs-control.vjs-visible-text {
  width: 100vw;

}

.video-parent .video-js .vjs-control.vjs-visible-text span {
  font-size: 24px;
  padding-top: .5rem;
}

.video-parent .vjs-playback-rate .vjs-menu {
  width: 10rem;
  left: -3rem;
  top: 1.5rem;
}


.video-parent .vjs-layout-large .vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  height: auto;
  /* position: relative; */

}

.video-parent .vjs-skip-backward-10 {
  height: 2.5rem;
  order: -4;
}

.video-parent .vjs-skip-forward-10 {
  order: -2;
  height: 2.5rem;
}

.video-parent .vjs-volume-panel {
  order: -5;
  margin: 0 3rem !important;
  /* padding: 0 3rem !important; */
  z-index: 10;
}

.video-parent .vjs-play-control {
  order: -3;
  margin: 0 3rem !important;

}

.video-parent .vjs-play-control:hover .vjs-volume-control .vjs-control .vjs-volume-vertical {
  visibility: visible;
}

.video-js .vjs-volume-panel .vjs-volume-control.vjs-volume-vertical {
  height: 10em;
  padding: 1em auto;
}

.vjs-volume-bar.vjs-slider-vertical {
  height: 8em;
}

.video-js .vjs-volume-vertical {
  background-color: rgb(40, 40, 40);
  border-radius: .5rem;

}

.vjs-slider-vertical .vjs-volume-level {
  width: .5em;
  background-color: rgb(201, 0, 0);
}

.vjs-slider-vertical .vjs-volume-level:before {
  font-size: 1.2em;
  color: red;

}

.video-parent .vjs-subs-caps-button {
  order: -1;
  /* margin: 0 3rem !important; */
  margin: 0 0 !important;


}


.video .vjs-fullscreen-control {
  position: absolute !important;
  top: 1vh;
  right: 2vw;
  height: 5rem !important;
}

.video-parent .vjs-volume-panel-horizontal {
  height: 1em;

}

.video-parent .vjs-icon-replay:before {
  content: "";
}


/* .video-parent .video-js .vjs-skip-forward-10 {
  position: fixed !important;
  top: 50%;
  right: 25%;
} */

.video-parent .vjs-icon-replay-10:before,
.video-parent .video-js .vjs-skip-backward-10 .vjs-icon-placeholder:before {
  content: "";
  background: url("../assets/images/backward.png") center no-repeat;
  background-size: contain;
  width: 35px;
  /* position: fixed;
  left: 25%; */
}

.video-parent .vjs-icon-forward-10:before,
.video-js .vjs-skip-forward-10 .vjs-icon-placeholder:before {
  content: "";
  background: url("../assets/images/forward.png") center no-repeat;
  background-size: contain;
  width: 35px;
  /* position: fixed; */
  /* left: 70%; */
}

/* .video-parent .video-js .vjs-skip-backward-10 {
  position: fixed !important;
  top: 50%;
  left: 25%;
} */


.video-parent .skip-back .vjs-icon-replay:before {
  background: url("../assets/images/backward.png") center no-repeat;
  background-size: contain;
  /* margin:0 3rem !important; */


}


.video-parent .vjs-subs-caps-button .vjs-menu-content span {
  font-size: 14px;
}

.vjs-workinghover .vjs-menu-button-popup.vjs-hover .vjs-menu{
  display: none ;
}

.video-parent .vjs-menu li {
  border-radius: .5rem;
  /* margin: 2px; */
  /* background: rgb(0, 0, 0);
  color: #ffffff; */

}

.video-parent .vjs-playback-rate {
  position: absolute !important;
  top: 1vh;
  right: 10rem;
  height: 5rem !important;
}

.video-parent .vjs-playback-rate .vjs-menu {
  position: relative !important;
  left: -2.5rem;
  height: fit-content !important;
  width: 8rem;
  /* top: 10rem; */
}

.video-parent .vjs-fullscreen-control {
  position: absolute !important;
  /* top: 100%; */
  /* right: 2vw; */

  /* height: 5rem !important; */
}

.stream .vjs-fullscreen-control {
  position: relative !important;
  bottom: 0;
  /* right: 0; */
  height: auto !important;
  margin: 0 3rem;
  /* padding: 0 3rem; */
}

.stream .vjs-control-bar {
  background-color: hsla(0, 0%, 0%, 0.512) !important;
}

/* .video-parent .vjs-fullscreen-control .vjs-icon-placeholder:before {
  height: 4rem;
} */

.video-parent .vjs-button>.vjs-icon-placeholder:before {
  font-size: 1.5em;
  line-height: 1.5em
}

.vjs-texttrack-settings {
  display: none;
}

.vjs-icon-fullscreen-enter:before,
.video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: "";
  background: url("../assets/images/zoom_out.png") center no-repeat;

}

.stream .vjs-icon-fullscreen-enter:before,
.stream .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {

  height: 3rem;
  /* margin: 0 3rem; */
}

.vjs-icon-fullscreen-exit:before,
.video-js.vjs-fullscreen .vjs-fullscreen-control .vjs-icon-placeholder:before {
  content: "";
  background: url("../assets/images/zoom_in.png") center no-repeat;
}

.video-parent .ads-icon {
  display: none;
  position: fixed;
  top: 4vh;
  right: 2vw;
  height: auto;
  padding: 1rem;
}

.ads-icon .vjs-icon-placeholder:before {
  content: "";
  background: url("../assets/images/ads-icon.png") center no-repeat;
  background-size: contain;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: "";
  background: url("../assets/regal_images/loader.gif") center no-repeat !important;
  background-size: contain !important;
  width: 10vw;
  height: 10vh;
}

.video-js .vjs-big-play-button {
  border: none;
  border-radius: 0;
  top: 45vh !important;
  left: 50vw !important;
  background-color: transparent !important;
}

.video-js:hover .vjs-big-play-button {
  background-color: transparent !important;
}

.go-back {
  z-index: 10;
  position: absolute !important;
  left: 2vw;
  margin-left: 1rem !important;
  bottom: 0;
  top: 1vh;
  cursor: pointer;
  height: 5rem !important;
}

.go-back .vjs-icon-placeholder:before {
  content: "";
  background: url("../assets/regal_images/left-arrow.png") center no-repeat !important;
  background-size: contain !important;

}
.free-preview-class {
  z-index: 10;
  position: absolute !important;
  left: 2vw;
  margin-left: 1rem !important;
  bottom: 0;
  top: 6vh;
  cursor: pointer;
  height: 5rem !important;
}



.skip-intro {
  /* z-index: 10; */
  display: block;
  position: fixed !important;
  top: 10%;
  right: 10%;
  cursor: pointer;
}

.skip-intro .vjs-icon-placeholder:before {
  content: "";
  /* background: url("../assets/regal_images/skip (2).png") center no-repeat !important; */
  height: 100px;
  width: 100px;
  background-size: contain !important;
  display: block;
}

.next-episode-play {
  display: block;
  position: fixed !important;
  top: 10%;
  right: 15%;
  cursor: pointer;
}

.next-episode-play .vjs-icon-placeholder:before {
  content: "";
  background: url("../assets/regal_images/next-episode.png") center no-repeat !important;
  height: 150px;
  width: 150px;
  background-size: contain !important;
  display: block;
  z-index: 999 !important;
}

.vjs-tip {
  display: none !important;
}

.recomendedMovies-player {
  position: fixed;
  top: 40%;
  right: 10%;
  height: 200px;
  width: 400px;
  opacity: 0;
  background-size: cover;
  animation: test 1s forwards;
  cursor: pointer;
  border-radius: 1rem;
}

@keyframes test {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.related-movie-name {
  /* position: fixed;
  top: 0;
  left: 0; */
  font-size: 25px;
  padding: 20px;
}
.audio_subtitle_resolution_controls{ order: -1;
  margin: 0 3rem !important;
 
}

.audio_subtitle_resolution_controls>button .vjs-icon-placeholder {
  width: 30px;
  height: 30px;
  background: url("../assets/regal_images/language.png") center no-repeat;
  background-size: contain;
  margin-right: 1rem;
}

.audio_subtitle_resolution_controls > button  .vjs-icon-placeholder::before{
  content: "";
 
}
.audio_subtitle_resolution_controls>.vjs-menu>.vjs-menu-content{
  position: absolute;
  height: 70vh !important;
  /* height: 55vh !important; */
  width: 45vw !important;
  max-height: 100vh;
  top: -80vh;
  right: -9vw;
  background-color: rgba(0, 0, 0, 0.507) !important;
  overflow: visible;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  /* grid-template-columns: 50% 50%; */
  z-index: 11;
  border-radius: 10px;
}
.custom-li .vjs-menu.always-open{
  display: block !important;
 
 }
 .always-open {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}
button:focus-visible,li:focus-visible{
outline: none !important;
}
.custom-li button.vjs-menu-button{
height: 30px !important;
border-bottom: 2px rgba(255, 255, 255, 0.59) solid;
}
.vjs-menu .always-open{
position: absolute;
top: 0vh;
height: 55vh;
}
.custom-li .vjs-menu-content{
top:5vh;
background-color: transparent !important;
max-height: max-content !important;
padding: 10px;
}
.vjs-menu-button-popup .vjs-menu{
width: 100%;
left: 0;
height: auto;
    bottom: auto;
}
.custom-li .vjs-menu-button{
width: 100%;
}
.vjs-menu li.vjs-menu-item{
background-color: transparent !important;
text-transform: capitalize;

}
.vjs-menu li.vjs-selected{
background-color: white !important;
border-radius: 10px;
text-transform: capitalize;
}
.li-second ul
{
border: 1px rgba(255, 255, 255, 0.156) solid;
border-top: none;
border-bottom: none;
}
.vjs-icon-hd:before,.vjs-subs-caps-button .vjs-icon-placeholder:before,.video-js .vjs-audio-button .vjs-icon-placeholder:before{
content:"Quality" !important;
font-size: 20px !important;
font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.vjs-subs-caps-button .vjs-icon-placeholder:before{
content:"Subtitle" !important;
}
.video-js .vjs-audio-button .vjs-icon-placeholder:before{
content:"Audio" !important;
}
.custom-li span{
font-size: 18px !important;
font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.vjs-menu-title,.vjs-texttrack-settings{
display: none !important;
}
.vjs-button > .vjs-icon-placeholder:before {
font-size: 1.8em;
line-height: 1.8;
}
.vjs-menu li {
padding: .5em 0;
}
/* .video-parent .vjs-menu-button-popup {
  background-color: rebeccapurple !important;
  right: 9rem !important;
  width: 15rem;
} */
button.vjs-menu-button{
  height: 2.5rem !important;
}
.vjs-layout-large .vjs-menu-button-popup .vjs-menu .vjs-menu-content{
  max-height: max-content !important;
}
.live-tv .vjs-quality-selector{
  display: none !important;
}

.margin-custom-audio{
  /* margin-top: 35px; */
}
.vjs-layout-x-large .vjs-menu-button-popup .vjs-menu .vjs-menu-content{
  max-height: 30em;
}


.selected-class{
  background-color: #ffffff !important;
  font-size: 50px !important;
}

.vjs-menu-selected{
  background-color: #ffffff;
}

.time{
  color: red;
}