.overlay-shadow {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 90vh;
	/* background-color: rgb(0, 0, 0,.7);
     */
	/* background-image: linear-gradient(
			0deg,
			rgba(0, 0, 0, 0),
			rgba(0,0, 0, 0.3) 50.62%,
			rgba(0, 0, 0, 0.7) 100%,
			rgb(0, 0, 0)
		),
		linear-gradient(
			90deg,
			rgb(0, 0, 0) 1.48%,
			rgba(0, 0, 0, 0.8) 21.89%,
			rgba(0, 0, 0, 0.5) 36.68%,
			rgba(0, 0, 0, 0.9) 74.14%,
			rgb(0, 0, 0)
		); */

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.overlay-shadow p {
	padding: 1rem;
	text-align: center;
	margin-top: -2rem;
	font-size: 40px;
	font-weight: 600;
}
.marquee {
	/* background-color: #ddd; */
	/* width: 500px; */
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	overflow: hidden;
	white-space: nowrap;
	height: 90vh;
}
.marquee img {
	display: inline-block;
	font-size: 20px;
	position: relative;
	top: 20px;
	/* animation: marquee 20s linear infinite; */
}
.marquee span:nth-child(2) {
	animation-delay: 0s;
}
.marquee span:nth-child(1) {
	animation-delay: 4s;
}
@keyframes marquee {
	0% {
		transform: translateY(0%);
	}
	50% {
		transform: translateY(-50%);
	}

	100% {
		transform: translateY(0%);
	}
}
